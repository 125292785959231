import { DynamicPageProps } from '@doltech/routeDefinition';
import * as React from 'react';
import { useGetAuthInfo } from '@doltech/core/lib/auth/withGlobalUserAuthInfo';
import { GrammarTemplateTypeEnum } from '@doltech/domains/landing-grammar/lib/shared/constants';
import { observer } from 'mobx-react-lite';
import PageNotFound from '../pages/404';
import dynamic from 'next/dynamic';
import { GrammarLandingPageLayout } from '@doltech/domains/landing-grammar/lib/landing/GrammarLandingPageLayout';

export interface Props extends DynamicPageProps {
  theoryList: any;
  exerciseList: any;
  topicList: any;
  pageType?: string;
}

export interface GrammarTemplateProps extends DynamicPageProps {
  theoryList: any;
  exerciseList: any;
  topicList: any;
  pageType?: string;
  isLoggedIn?: boolean;
}

const GrammarBlogExerciseAuthLayoutTemplatePage = dynamic(
  () => import('./GrammarBlogExerciseAuthLayoutTemplatePage'),
  { loading: () => <GrammarLandingPageLayout loading /> }
);
const GrammarBlogExerciseNotAuthLayoutTemplatePage = dynamic(
  () => import('./GrammarBlogExerciseNotAuthLayoutTemplatePage'),
  { loading: () => <GrammarLandingPageLayout loading /> }
);

const GrammarBlogTheoryAuthLayoutTemplatePage = dynamic(
  () => import('./GrammarBlogTheoryAuthLayoutTemplatePage'),
  { loading: () => <GrammarLandingPageLayout loading /> }
);

const GrammarBlogTheoryNotAuthLayoutTemplatePage = dynamic(
  () => import('./GrammarBlogTheoryNotAuthLayoutTemplatePage'),
  { loading: () => <GrammarLandingPageLayout loading /> }
);

const GrammarLandingExercisePageLayoutTemplatePage = dynamic(
  () => import('./GrammarLandingExercisePageLayoutTemplatePage'),
  { loading: () => <GrammarLandingPageLayout loading /> }
);

const GrammarLandingHomePageAuthLayoutTemplatePage = dynamic(
  () => import('./GrammarLandingHomePageAuthLayoutTemplatePage'),
  { loading: () => <GrammarLandingPageLayout loading /> }
);

const GrammarLandingHomePageNotAuthLayoutTemplatePage = dynamic(
  () => import('./GrammarLandingHomePageNotAuthLayoutTemplatePage'),
  { loading: () => <GrammarLandingPageLayout loading /> }
);
const GrammarLandingTheoryPageLayoutTemplatePage = dynamic(
  () => import('./GrammarLandingTheoryPageLayoutTemplatePage'),
  { loading: () => <GrammarLandingPageLayout loading /> }
);

const GrammarLandingAuthorPageLayoutTemplatePage = dynamic(
  () => import('./GrammarLandingAuthorPageLayoutTemplatePage'),
  { loading: () => <GrammarLandingPageLayout loading /> }
);

const LandingGrammarTemplatePage = observer((props: Props) => {
  const { pageType } = props;
  const { isLoggedIn } = useGetAuthInfo();

  switch (pageType) {
    case GrammarTemplateTypeEnum.LANDING_HOME: {
      return isLoggedIn ? (
        <GrammarLandingHomePageAuthLayoutTemplatePage isLoggedIn={isLoggedIn} {...props} />
      ) : (
        <GrammarLandingHomePageNotAuthLayoutTemplatePage {...props} />
      );
    }
    case GrammarTemplateTypeEnum.LANDING_GRAMMAR_THEORY: {
      return <GrammarLandingTheoryPageLayoutTemplatePage isLoggedIn={isLoggedIn} {...props} />;
    }
    case GrammarTemplateTypeEnum.LANDING_GRAMMAR_EXERCISE: {
      return <GrammarLandingExercisePageLayoutTemplatePage isLoggedIn={isLoggedIn} {...props} />;
    }
    case GrammarTemplateTypeEnum.BLOG_GRAMMAR_THEORY: {
      return isLoggedIn ? (
        <GrammarBlogTheoryAuthLayoutTemplatePage {...props} />
      ) : (
        <GrammarBlogTheoryNotAuthLayoutTemplatePage {...props} />
      );
    }
    case GrammarTemplateTypeEnum.BLOG_GRAMMAR_EXERCISE: {
      return isLoggedIn ? (
        <GrammarBlogExerciseAuthLayoutTemplatePage {...props} />
      ) : (
        <GrammarBlogExerciseNotAuthLayoutTemplatePage {...props} />
      );
    }
    case GrammarTemplateTypeEnum.BLOG_GRAMMAR_AUTHOR: {
      return <GrammarLandingAuthorPageLayoutTemplatePage isLoggedIn={isLoggedIn} {...props} />;
    }
    case 'NOT_FOUND_GRAMMAR': {
      return <PageNotFound />;
    }
    default:
      return <PageNotFound />;
  }
});

export default LandingGrammarTemplatePage;
