import { encryptData } from '@doltech/core/lib/encrypt-page-data/encryptData';
import * as React from 'react';

import { getDataPreRendering } from '../routers/route';
import LandingGrammarTemplatePage from '../templates/LandingGrammarTemplatePage';
import { CommonSlugPage } from '@doltech/ui/lib/shared/components/SlugPage/CommonSlugPage';
import { GrammarLandingPageLayout } from '@doltech/domains/landing-grammar/lib/landing/GrammarLandingPageLayout';
import { getLandingGrammarPageByTemplateTypes } from '@doltech/domains/landing-grammar/lib/shared/apis/landing-grammar';
import {
  GrammarTemplateTypeEnum,
  GrammarTemplateTypeList,
} from '@doltech/domains/landing-grammar/lib/shared/constants';
import { environment } from '@doltech/core/lib/environment/environment';

export function Index(props) {
  const { data, otherUrlInfo, rawPageData } = props;
  const { seoHomePage } = data || {};
  const canonicalUrl = rawPageData?.canonicalUrl
    ? rawPageData?.canonicalUrl
    : environment.REACT_APP_DOL_GRAMMAR_WEBSITE_HOST_NAME;

  return (
    <CommonSlugPage
      {...props}
      urlInfo={seoHomePage}
      hardCodeHeadTags={`<link rel="canonical" href="${canonicalUrl}" />`}
    >
      {() => (
        <GrammarLandingPageLayout otherUrlInfo={otherUrlInfo} hasPromotion>
          <LandingGrammarTemplatePage
            {...data}
            pageType={GrammarTemplateTypeEnum?.LANDING_HOME}
            otherUrlInfo={otherUrlInfo}
          />
        </GrammarLandingPageLayout>
      )}
    </CommonSlugPage>
  );
}

const buildSEOInfo = ({ urlInfo }) => {
  const structuralSeo = (urlInfo?.metadata?.toString() || '').replace(
    /\$REPLACE_DOMAIN_HERE\$/g,
    `${environment.REACT_APP_DOL_GRAMMAR_WEBSITE_HOST_NAME}`
  );
  const schemaScriptString = (urlInfo?.schemaHtml || '').replace(
    /\$REPLACE_DOMAIN_HERE\$/g,
    `${environment.REACT_APP_DOL_GRAMMAR_WEBSITE_HOST_NAME}`
  );
  return {
    structuralSeo,
    schemaScriptString,
  };
};
export default Index;

export const getServerSideProps = async (context) => {
  const { res } = context;

  res.setHeader('Cache-Control', 'public, s-maxage=3600, stale-while-revalidate=1800');
  // config api
  const { data: otherUrlInfo } = await getLandingGrammarPageByTemplateTypes(
    GrammarTemplateTypeList
  );
  const { data: rawPageData } = await getLandingGrammarPageByTemplateTypes(
    GrammarTemplateTypeEnum.LANDING_HOME
  );
  const data = await getDataPreRendering({ templateTypeId: GrammarTemplateTypeEnum.LANDING_HOME });
  const { structuralSeo, schemaScriptString } = buildSEOInfo({ urlInfo: rawPageData[0] });

  return {
    props: encryptData({
      structuralSeo,
      schemaScriptString,
      isStructuralSEO: false,
      data: data?.pageData,
      otherUrlInfo,
      rawPageData,
    }),
  };
};
