import * as React from 'react';
import parse from 'html-react-parser';

const isServerSide = typeof window === 'undefined';
const isClientSide = !isServerSide;

const allowedClientSideTags = ['title', 'meta', 'link'];

const mergeMetaTags = ({ hardCodeHeadTags = '', structuralSeo, parseLocalSeoMedataOption }) => {
  let hardCodeHeadTagsParsed = parse(hardCodeHeadTags, parseLocalSeoMedataOption) as any;
  const structuralSeoParsed = parse(structuralSeo, parseLocalSeoMedataOption) as any[];

  if (hardCodeHeadTagsParsed?.type) {
    hardCodeHeadTagsParsed = [hardCodeHeadTagsParsed];
  }

  const uniqueTags = structuralSeoParsed;
  if (hardCodeHeadTagsParsed) {
    console.log(
      'XXX1',
      hardCodeHeadTagsParsed.filter((o) => o?.type)
    );
    console.log('XXX2', hardCodeHeadTagsParsed);
    hardCodeHeadTagsParsed
      .filter((o) => o?.type)
      .forEach((tag) => {
        const existHardCodeTag = structuralSeoParsed.find(
          (sTag) => sTag.type === tag.type && sTag?.props?.property === tag?.props?.property
        );
        console.log('XXXX', existHardCodeTag);

        if (!existHardCodeTag) {
          uniqueTags.push(tag);
        }
      });
  }

  return uniqueTags.filter((o) => o?.type);
};

export const useBuildSeoMeta = ({
  hardCodeHeadTags,
  schemaScriptString,
  structuralSeo,
  schemaBlogPosting = undefined,
  parseLocalSeoMedataOption,
}) => {
  const { schemaBlogPostingScript, seoMetadata, schemaScript, error } = React.useMemo(() => {
    let localSeoMetadata = null;
    let localSchemaScript = null;
    let localError = null;
    let localSchemaBlogPostingScript = null;

    if (structuralSeo) {
      try {
        localSeoMetadata = mergeMetaTags({
          hardCodeHeadTags,
          structuralSeo,
          parseLocalSeoMedataOption,
        });
      } catch (ex) {
        localError = ex;
      }
    }

    if (isClientSide && localSeoMetadata) {
      localSeoMetadata = localSeoMetadata.filter((meta) =>
        allowedClientSideTags.includes(meta?.type)
      );
    }

    if (schemaScriptString) {
      try {
        localSchemaScript = parse(schemaScriptString, parseLocalSeoMedataOption);
      } catch (ex) {
        localError = ex;
      }
    }

    if (schemaBlogPosting) {
      try {
        localSchemaBlogPostingScript = parse(schemaBlogPosting, parseLocalSeoMedataOption);
      } catch (ex) {
        localError = ex;
      }
    }

    return {
      seoMetadata: localSeoMetadata,
      schemaScript: localSchemaScript,
      schemaBlogPostingScript: localSchemaBlogPostingScript,
      error: localError,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    seoMetadata,
    schemaScript,
    schemaBlogPostingScript,
    error,
  };
};
